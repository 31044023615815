import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import IdField from '@/shared/fields/id-field';
import IntegerField from '@/shared/fields/integer-field';
import { WorkspaceTypeField } from '@/modules/archive/fields/workspace-type-field';
import { DocumentTypeField } from '@/modules/archive/fields/document-type-field';

const field = (name) => i18n.t(`ARCHIVE.SUBFOLDER.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ARCHIVE.SUBFOLDER.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    id: new IdField('id', 'id'),
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        { placeholder: placeholder('name')},
    ),
    type: new StringField(
        'type',
        field('type'),
    ),
    createdDate: new DateField(
        'createdDate',
        field('created_date'),
    ),
    description: new DateField(
        'description',
        field('description'),
        { required: true },
    ),
    dimension: new StringField(
        'dimension',
        field('dimension'),
    ),
    documentsNumber: new IntegerField(
        'nrDocumente',
        field('document_number'),
    ),
    workspace: WorkspaceTypeField.relationToOne(
        'workspace',
        field('workspace'),
        { required: true },
    ),
    documentTypeCode: DocumentTypeField.relationToOne(
        'documentTypeCode',
        field('document_type_code'),
        { required: true },
    ),
};

export class SubfolderModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
