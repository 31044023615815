export const WORKSPACES = {
    data: [
       {
            name: 'D.U.A.T',
            created_date: '2021-05-18T10:30:06.168Z',
            id: '1',
            type: 'Workspace',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
            url: 'duat'
        },
        {
            name: 'A.C.',
            created_date: '2021-05-10T10:30:06.168Z',
            id: '2',
            type: 'Workspace',
            archive_date: '2021.05.11',
            dimension: '10.1 GB',
            url: 'ac'
        },
        {
            name: 'A.D.',
            created_date: '2021-05-11T10:30:06.168Z',
            id: '3',
            type: 'Workspace',
            archive_date: '2021.05.12',
            dimension: '2.2 GB',
            url: 'ad'
        }
    ],
    pagination: {
        count: 1,
        first: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        last: '/workspaces?skip=-9&limit=10&sort=&envelope=true',
        limit: 10,
        next: '/workspaces?skip=10&limit=10&sort=&envelope=true',
        prev: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        self: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        skip: 0,
        template: '/workspaces?skip={skip}&limit={limit}&sort={sortString}&envelope={envelope}&pretty={pretty}&fields={fields}',
        total: 3
    }
}


export const UC = {
    data: [
        {
            id: '1',
            number: '382',
            name: 'CU_1_2020_01_07.pdf',
            type: 'Certificat de urbanism',
            beneficiary: 'John Doe',
            issuer: 'CJ Cluj',
            uat: 'Cluj-Napoca',
            submited_date: '2021.04.12',
            release_date: '2021.05.12',
        },
        {
            id: '2',
            number: '382.',
            name: 'CU_1_2020_01_07.pdf',
            type: 'Certificat de urbanism',
            beneficiary: 'John Doe1',
            issuer: 'CJ Cluj',
            uat: 'Cluj-Napoca',
            submited_date: '2021.04.12',
            release_date: '2021.05.12',
        },
        {
            id: '3',
            number: '382.',
            name: 'CU_1_2020_01_07.pdf',
            type: 'Certificat de urbanism',
            beneficiary: 'Gary Payton',
            issuer: 'CJ Cluj',
            uat: 'Cluj-Napoca',
            submited_date: '2021.04.12',
            release_date: '2021.05.12',
        },
        {
            id: '4',
            number: '382.',
            name: 'CU_1_2020_01_07.pdf',
            type: 'Certificat de urbanism',
            beneficiary: 'Jane Doe',
            issuer: 'CJ Cluj',
            uat: 'Cluj-Napoca',
            submited_date: '2021.04.12',
            release_date: '2021.05.12',
        },
    ],
    pagination: {
        count: 1,
        first: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        last: '/workspaces?skip=-9&limit=10&sort=&envelope=true',
        limit: 10,
        next: '/workspaces?skip=10&limit=10&sort=&envelope=true',
        prev: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        self: '/workspaces?skip=0&limit=10&sort=&envelope=true',
        skip: 0,
        template: '/workspaces?skip={skip}&limit={limit}&sort={sortString}&envelope={envelope}&pretty={pretty}&fields={fields}',
        total: 4
    }
}
export const SUBFOLDERS = {
    data: [
        {
            id: '1',
            name: 'Corespondenta',
            type: 'Folder',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
            workspace: '1',
            correlated_document: 'CU'
        },
        {
            id: '2',
            name: 'Certificat de urbanism',
            type: 'Folder',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
            workspace: '1',
            correlated_document: 'CU'
        },
        {
            id: '3',
            name: 'Aviz cu',
            type: 'Folder',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
            workspace: '1',
            correlated_document: 'CU'
        },
        {
            id: '4',
            name: 'Aviz Arh sef',
            type: 'Folder',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
        },
        {
            id: '5',
            name: 'PVCC',
            type: 'Folder',
            archive_date: '2021.05.10',
            dimension: '83.9 GB',
        },
    ],
    pagination: {
        count: 1,
        first: '/subfolders?skip=0&limit=10&sort=&envelope=true',
        last: '/subfolders?skip=-9&limit=10&sort=&envelope=true',
        limit: 10,
        next: '/subfolders?skip=10&limit=10&sort=&envelope=true',
        prev: '/subfolders?skip=0&limit=10&sort=&envelope=true',
        self: '/subfolders?skip=0&limit=10&sort=&envelope=true',
        skip: 0,
        template: '/subfolders?skip={skip}&limit={limit}&sort={sortString}&envelope={envelope}&pretty={pretty}&fields={fields}',
        total: 5
    }
}

export const DOCUMENT_TYPES = {
    data: [
        {
            code: 'DT2107221514541454',
            id: '9485',
            name: 'Certificat de urbanism',
        },
        {
            code: 'DT210713010718643',
            id: '290',
            name: 'Factura',
        },
        {
            code: 'DT210713010719253',
            id: '299',
            name: 'P&L',
        },
    ],
}
