<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :firstColumnIcons="firstColumnIcons"
            :loading="isLoading"
            @view="onView"
            @edit="onEdit"
            @delete="onDelete"
            :noSelectOnClick="noSelectOnClick"
            @onRowClicked="onRowClicked"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { SubfolderModel } from '@/modules/archive/models/subfolder-model';

    const { fields } = SubfolderModel;

    export default {
        name: 'SubfolderListTable',
        props: {
            updatedSubfolder: {
                type: Object,
                default: () => ({ }),
            },
            editMode: {
                type: Boolean,
                default: false
            },
            workspace: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                presenter: SubfolderModel.presenter,
                fields: [
                    fields.name.extendField({ bold: true }),
                    fields.createdDate.extendField({ bold: true }),
                    fields.dimension,
                    fields.documentsNumber,
                ],
                firstColumnIcons: {
                    name: 'folder',
                    customIcon: false,
                    type: 'primary'
                },
                noSelectOnClick: true,
                rows: []
            };
        },
        computed: {
            ...mapGetters({
                // Row will remain empty until new updated
                // rows: 'archive/subfolders/rows',
                loading: 'shared/loading',
                archivePermissions: 'archive/archivePermissions'
            }),
            isLoading() {
                return this.loading['archive/getSubfolders']
            },
            actions() {
                return [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.archivePermissions.hasPermissionToEdit
                    },
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        disabled: false,
                        type: 'danger',
                        permission: this.archivePermissions.hasPermissionToDestroy
                    },
                ]
            }
        },
        methods: {
            ...mapActions({
                deleteSubfolder: 'archive/subfoldersForm/remove',
            }),
            onView(data) {
                this.$router.push({
                    name: 'archive-documents',
                    params: {
                        id: data.item.id,
                        folder: data.item,
                        workspace: this.$route.params.workspace
                    }
                });
            },
            onEdit(data) {
                this.$emit('editSubfolder', data);
            },
            onRowClicked(data) {
                // TODO: Check if selected row is folder or file after BE is done
                this.$router.push({
                    name: 'archive-documents',
                    params: {
                        id: data.id,
                        folder: data,
                        workspace: this.$route.params.workspace
                    }
                });
            },
            async onDelete(data) {
                await this.deleteSubfolder(data.item);
                this.$emit('updateFolder');
            },
        },
    };
</script>
