import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { validators } from '@/shared/form/validators';

export default class IntegerField extends GenericField {
    constructor(
        name,
        label,
        {
            required = false,
            min = undefined,
            max = undefined,
        } = {},
        filterParams = {},
    ) {
        super(name, label, filterParams);

        this.type = 'integer';
        this.required = required;
        this.min = min;
        this.max = max;
    }

    forPresenter(value) {
        return value;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFilterInitialValue(value) {
        return value;
    }

    forFilterRules() {
        return undefined;
    }

    forFilterCast() {
        return yup
            .number()
            .integer()
            .nullable(true)
            .label(this.label);
    }

    forFormCast() {
        let yupChain = yup
            .number()
            .integer()
            .nullable(true)
            // checking self-equality works for NaN, transforming it to null
            .transform((_, val) => val ? Number(val) : null)
            .label(this.label);

        return yupChain;
    }

    forFormRules() {
        let output = {
            ...validators.integer(),
        };

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }

        if (this.min || this.min === 0) {
            output = {
                ...output,
                ...validators.min(this.min),
            };
        }

        if (this.max || this.max === 0) {
            output = {
                ...output,
                ...validators.max(this.max),
            };
        }

        return output;
    }
}
