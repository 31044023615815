const calcPaginationParams = (currentPage, perPage) => {
    return {
        offset: (currentPage - 1) * perPage,
        pageSize: perPage,
    }
}

const calcArchiveParams = (params) => {
    const { currentPage, perPage} = params;
    return calcPaginationParams(currentPage, perPage);
}

export default calcArchiveParams;
