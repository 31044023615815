<template>
    <st-filters-pagination
        :perPage=10
        stateModule="archive/subfolders"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
        :header="workspace.name"
        :calcParams="calcArchiveParams"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import calcArchiveParams from '@/shared/utils/calc-archive-query-params';
    import { FilterSchema } from '@/shared/form/filter-schema';
    const filterSchema = new FilterSchema([]);

    export default {
        name: 'SubfolderListFilter',
        props: {
            workspace: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filters: filterSchema.fields,
                calcArchiveParams,
                total: 0
            }
        },
        computed: {
            ...mapGetters({
                // total will remain 0 until new updates
                //    total: 'archive/subfolders/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'archive/subfolders/getSubfolders',
            }),
            refresh() {
                this.doFilter({offset: 0, pageSize: 10});
            },
            doFilter(params) {
                this.doFetch({payload: params, id: this.workspace.documentLocationRealName});
            },
        },
    };
</script>
