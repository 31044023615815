<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st-subfolder-form"
        >
            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    v-model="model[fields.name.name]"
                    :name="fields.name.name"
                    :ref="fields.name.name"
                />
            </div>
            <div class="form-group">
                <label>
                    {{fields.documentTypeCode.label}} *
                </label>
                <b-form-select
                    v-model="model[fields.documentTypeCode.name]"
                    :name="fields.documentTypeCode.name"
                    :options="fields.documentTypeCode.options"
                >
                </b-form-select>
            </div>
            <div class="form-group">
            <label>
                {{ fields.description.label }} *
            </label>
            <b-form-textarea
                v-model="model[fields.description.name]"
                class="st-form-textarea"
                rows="5"
                no-resize
                :ref="fields.description.name"
                :name="fields.description.name"
            >
            </b-form-textarea>
        </div>
        </form>

        <div class="pt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel"/>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { SubfolderModel } from '@/modules/archive/models/subfolder-model';

const { fields } = SubfolderModel;
const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.description,
    fields.documentTypeCode,
])

export default {
    name: 'SubfolderForm',
    props: {
        selectedSubfolder: {
            type: Object,
            required: true,
            default: () => ({ }),
        },
        selectedWorkspace: {
            type: Object,
            required: true,
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: formSchema.initialValues(this.selectedSubfolder),
            fields,
            rules: formSchema.rules(),
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
        }),
    },
    mounted() {
        this.fv = createFormValidation('st-subfolder-form', this.rules);
    },
    methods: {
        ...mapActions({
            addSubfolder: 'archive/subfoldersForm/create',
            updateSubfolder: 'archive/subfoldersForm/update'
        }),
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                if (this.editMode) {
                    this.onUpdateSubfolder(cast);
                } else {
                    this.onAddSubfolder(cast);
                }
            }
        },
        onAddSubfolder(subfolder) {
            const payload = {
                ...subfolder,
                author: this.currentUser.email,
                documentLocationRealName: this.selectedWorkspace.realName,
                id: null,
                parentId: null,
                permissions: [
                    {
                    "permission": "1111",
                    "entityType": 3,
                    "entityId": "30"
                    }
                ],
            }
            this.addSubfolder(payload)
            .then(() => {
                this.$emit('submitForm');
            })
        },
        onUpdateSubfolder (subfolder) {
            const payload = {
                ...subfolder,
                author: this.currentUser.email,
                documentLocationRealName: this.selectedWorkspace.realName,
                parentId: null,
                permissions: [
                    {
                    "permission": "1111",
                    "entityType": 3,
                    "entityId": "30"
                    }
                ],
            }
            this.updateSubfolder(payload)
            .then(() => {
                this.$emit('submitForm');
            })
        },
        cancel() {
            this.$emit('cancelForm');
        },
    },
}
</script>
