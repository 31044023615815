<template>
    <st-page
        :title="title"
        >
        <template #toolbar>
            <st-button
                v-if="false"
                v-can:admin="'archive'"
                variant="secondary"
                :callback="addSubfolder"
                :spinner="loading['archive/subfoldersForm/create']"
            >
                <i class="fa fa-plus"></i>
                {{$t('ARCHIVE.SUBFOLDER.ACTIONS.ADD')}}
            </st-button>
        </template>
        <subfolder-list-filter
            ref="listSubfolderFilter"
            :workspace="$route.params.workspace"
        >
            <subfolder-list-table
                @editSubfolder="editSubfolder"
                ref="list-subfolder-table"
                :workspace="selectedWorkspace"
                :edit-mode="editMode"
                @updateFolder="updateFolder"
                />
        </subfolder-list-filter>
        <subfolder-modal
            ref="manage-folder"
            :selectedSubfolder="selectedSubfolder"
            :selectedWorkspace="selectedWorkspace"
            @updateFolder="updateFolder"
            :edit-mode="editMode"
        ></subfolder-modal>
    </st-page>
</template>

<script>
    import SubfolderListFilter from '../components/subfolders/SubfolderListFilter';
    import SubfolderListTable from '../components/subfolders/SubfolderListTable';
    import SubfolderModal from '../components/subfolders/SubfolderModal';
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'SubfolderList',
        components: {
            SubfolderListFilter,
            SubfolderListTable,
            SubfolderModal
        },
        data() {
            return {
                selectedSubfolder: {},
                updatedSubfolder: {},
                editMode: false,
                selectedWorkspace: this.$route.params.workspace
            };
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
                breadcrumbs: 'archive/workspaces/breadcrumbs',
            }),
            title() {
                return `${this.$t('ARCHIVE.SUBFOLDER.LIST.TITLE')} /  ${this.$route.params.workspace.name}`;
            }
        },
        watch: {
            "$route.params.workspace": {
                deep: true,
                immediate: false,
                handler(workspace) {
                    this.selectedWorkspace = workspace;
                    this.$nextTick(() => {
                        this.$refs['listSubfolderFilter'].refresh();
                    });
                },
            },
        },
        methods: {
            ...mapActions({
                doFind: 'archive/subfolder/rows',
            }),
            showModal() {
                this.$refs['manage-folder'].show();
            },
            addSubfolder() {
                this.editMode = false;
                this.selectedSubfolder = {};
                this.showModal();
            },
            editSubfolder(data) {
                this.editMode = true;
                this.selectedSubfolder = data.item;
                this.showModal();
            },
            updateFolder() {
                this.$refs.listSubfolderFilter.refresh();
            }
        }
    }
</script>
