<template>
    <div>
        <st-modal
            id="st-subfolder-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            size="md"
            hideable
            ref="st-subfolder-modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <subfolder-form
                    @cancelForm="hide"
                    @submitForm="submit"
                    :selectedSubfolder="selectedSubfolder"
                    :selectedWorkspace="selectedWorkspace"
                    :editMode="editMode"
                >
                    <template #buttons="{ submit, cancel }">
                        <div class="d-flex justify-content-between">
                            <st-button
                                variant="link"
                                class="font-size-h6 px-5 py-3 my-3 mr-3"
                                :callback="cancel"
                            > {{ $t('GENERAL.BUTTON.CANCEL') }}
                            </st-button>
                            <st-button
                                variant="primary"
                                class="font-size-h6 px-5 py-3 my-3 mr-3"
                                :callback="submit"
                            >
                                <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                            </st-button>
                        </div>
                    </template>
                </subfolder-form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import SubfolderForm from './SubfolderForm.vue';

export default {
    name: 'SubfolderModal',
    components: {
        SubfolderForm
    },
    props: {
        selectedSubfolder: {
            type: Object,
            required: true
        },
        selectedWorkspace: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('ARCHIVE.SUBFOLDER.ACTIONS.EDIT') :
                this.$t('ARCHIVE.SUBFOLDER.ACTIONS.ADD');
        }
    },
    methods: {
        show() {
            this.$refs['st-subfolder-modal'].show();
        },
        hide() {
            this.$refs['st-subfolder-modal'].hide();
        },
        submit() {
            this.$emit('updateFolder');
            this.hide();
        },
    },
}
</script>
