import ApiService from '@/shared/services/api.service';
import {DOCUMENT_TYPES} from '@/core/mock/mock-data/mock-workspaces';

export class ArchiveService {
    static listWorkspaces(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveWorkspace/getWorkspaces', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listSubfolders(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveFolder/getFoldersFromWorkspace', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static listSubfoldersFromNode(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveFolder/getFoldersFromNode', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listDocuments(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveDocument/getPagedArchivedDocumentsFromFolderForDisplay', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listExpiringDocuments(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/ArchiveExpire/getExpiringDocuments', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getFolderTypeByDocumentCode(id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/DocumentTypeGxtService/getDocumentTypeByCode/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static createWorkspace(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/ArchiveWorkspace/addWorkspace', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static removeWorkspace(realName) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/ArchiveWorkspace/deleteWorkspace?realName=${realName}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateWorkspace(params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/ArchiveWorkspace/editWorkspace', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static listDocumentTypes() {
        // TODO: Update with api once is available
        return new Promise((resolve) => {
            resolve(DOCUMENT_TYPES)
        });
    }

    static createSubfolder(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/ArchiveFolder/saveFolder', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static removeSubfolder(subfolder) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/ArchiveFolder/deleteFolder?folderId=${subfolder.id}&workspaceRealName=${subfolder.documentLocationRealName}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateSubfolder(params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/ArchiveFolder/updateFolder', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static getArchivedDocument(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveDocument/getArchivedDocumentForDisplay', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getArchivedDocumentMetadata(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveDocument/getArchivedDocumentMetadata', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getArchivedDocumentAttachments(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveDocument/getArchivedDocumentAttachments', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static downloadDocumentAttachment(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/ArchiveDocument/downloadArchivedDocumentAttachment', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static upload(files) {
        return new Promise((resolve, reject) => {
            ApiService.setBaseUrl({isArchive: false})
            ApiService.post('/files/trusted-files?echo=true', files, {
               'content-type': 'multipart/form-data'
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => reject(error))
            .finally(() => {
                ApiService.setBaseUrl({isArchive: true})
            });
        });
    }
    static archiveDocument(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/ArchiveDocument/archiveDocument', params)
            .then((response) => {resolve(response)})
            .catch((error) => reject(error))
        });
    }

    static getAllArchiveDocumentsTypes() {
        ApiService.setBaseUrl({isArchive: true})
        return new Promise((resolve, reject) => {
            ApiService.post('/DocumentTypeGxtService/getAllDocumentTypesForDisplay')
                .then((response) => resolve(response))
                .catch((error) => reject(error))
                .finally(() => {
                    ApiService.setBaseUrl({isArchive: false})
                })

        });
    }

    static filterStorage(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/ArchiveDocumentStorage/filterStorage ', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
}
