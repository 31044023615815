import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ArchiveService } from '@/modules/archive/archive-service';

export class WorkspaceTypeField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    const params = {
                            isAscendingOrder: true,
                            offset: 0,
                            pageSize: 10,
                            sortField: 'realName',
                        }
                    ArchiveService.listWorkspaces({params})
                    .then((data) => {
                        resolve(data.elements);
                    })
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.realName,
                    text: record.name,
                };
            },
            options,
        );
    }
}
